import{ MomentPipe} from "./moment.pipe";
import {NgModule} from "@angular/core";
import { GetUserPipe } from './get-user.pipe';
import { GetSitePipe } from './get-site.pipe';
import {OrthographePipe} from "./orthographe.pipe";
import { ConvertTypeContactPipe } from './convert-type-contact.pipe';
import { CalculateDurationPipe } from './calculate-duration.pipe';
import { ColorDeliveryStatusPipe } from './color-delivery-status.pipe';
import { IconDeliveryStatusPipe } from './icon-delivery-status.pipe';
import { TranslateDeliveryStatusPipe } from './translate-delivery-status.pipe';
import { DeliverDurationPipe } from './deliver-duration.pipe';



@NgModule({
    imports: [],
    exports: [MomentPipe, GetUserPipe, GetSitePipe, OrthographePipe, ConvertTypeContactPipe, CalculateDurationPipe,
        ColorDeliveryStatusPipe, IconDeliveryStatusPipe, TranslateDeliveryStatusPipe, DeliverDurationPipe],
    declarations: [MomentPipe, GetUserPipe, GetSitePipe, OrthographePipe, ConvertTypeContactPipe, CalculateDurationPipe,
        ColorDeliveryStatusPipe, IconDeliveryStatusPipe, TranslateDeliveryStatusPipe, DeliverDurationPipe]
})
export class PipeModule {

}
